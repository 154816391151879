import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getData } from "../../api/dataSlice";
import { useAddTimelapseJobMutation, useCancelTimelapseGenerationMutation, useGetDataByCameraQuery } from "../../api/graphqlApi";
import { Alert, Box, Button, Checkbox, CircularProgress, Divider, FormControlLabel, FormGroup, LinearProgress, MenuItem, Slider, TextField, Typography, styled } from "@mui/material";
import { Download, Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useDebouncedCallback } from "use-debounce";
import { useSubscription } from "@apollo/client";
import { timelapseJobStatusUpdatedByUuid } from "../../api/graphqlSubscription";
import { saveFile } from "../Utilities";
import { useKeycloak } from "../Keycloak";
import DateRangePicker from "./DateRangePicker";

const PrimarySliderCustom = styled(Slider)({
    color: "#0096fb",
    '& .MuiSlider-markLabel[data-index="0"]': {
        display: "none",
    },
    '& .MuiSlider-markLabel[data-index="1"]': {
        display: "none",
    },
});

export default function Form({ initialSiderWidth, loading }) {
    
    const defaultJobStatus = {
        uUID: "",
        timelapseUrl: "",
        jobParameter: {
            state: "",
            delay: 0,
            dateCreated: "",
            userName: "",
            userMail: "",
            language: "",
        }
    };
    
    const defaultErrorMessages = {
        camera: null,
        timespanStart: null,
        timespanEnd: null,
        maxLength: null,
    };

    const cameras = window.conf.CAMERAS

    const [siderWidth, setSiderWidth] = useState(initialSiderWidth);
    const [jobStatus, setJobStatus] = useState(defaultJobStatus);
    const [isDownloadingVideo, setIsDownloadingVideo] = useState(false);
    const [delay, setDelay] = useState({ total: 0, current: 0 });
    const [isCanceling, setIsCanceling] = useState(false);
    const [downloadProgress, setDownloadProgress] = useState(0);
    const [quality, setQuality] = useState({ original: { width: 1920, height: 1080 }, reduced: { width: 1280, height: 720 } });
    const [alertMessage, setAlertMessage] = useState({ type: "error", message: null });
    const [errorMessages, setErrorMessages] = useState(defaultErrorMessages);
    const [isValidation, setIsValidation] = useState(false);
    const [addTimelapseJob, { data: job, isLoading: loadingJob, error }] = useAddTimelapseJobMutation();
    const [cancelTimelapseGeneration, { isLoading: loadingCancel, error: errorCancel }] = useCancelTimelapseGenerationMutation();
    
    const { t, i18n } = useTranslation();
    const { isLoading: isCameraLoading } = useGetDataByCameraQuery(null, { pollingInterval: 600000 });
    const { timestamp } = useSelector(getData);
    const { getUsername, getEmail } = useKeycloak()
    const { data: dataJobStatus, error: jobStatusError } = useSubscription(timelapseJobStatusUpdatedByUuid, { variables: { uUID: jobStatus?.uUID }, skip: !jobStatus?.uUID });
    const { LAYOUT: { TITLE } } = window.conf;

    const [controlledData, setControlledData] = useState({
        camera: cameras?.[0],
        timespanStart: timestamp,
        timespanEnd: timestamp,
        maxLength: 120, // optional
        width: quality.original.width, // optional
        height: quality.original.height, // optional,
        useRecommendedLength: true,
    });
    
    const updateSiderWidth = useDebouncedCallback(() => setSiderWidth(refSider.current?.clientWidth), 200);
    const jobProgress = (jobStatus.uUID && (jobStatus?.jobParameter.state !== "finished" || jobStatus?.jobParameter.state !== "failed")) || false;
    const disabledForm = isDownloadingVideo || loadingCancel || loadingJob || jobProgress || loading;
    const disabledButton = disabledForm || isValidation;
    const refSider = useRef();

    const onChangeSelectedCamera = (event) => {
        const id = event.target.value;
        const camera = cameras?.find((camera) => camera.id === id);
        setControlledData({ ...controlledData, camera });
        setAlertMessage(null);
    };

    const handleAddTimelapseJob = async() => {
        const timespanStart = controlledData.timespanStart.format("YYYY-MM-DD") + "T00:00:00.000Z";
        const timespanEnd = controlledData.timespanEnd.format("YYYY-MM-DD") + "T23:59:59.999Z";

        const data = {
            featureDetailId: controlledData.camera.featureDetailId,
            timespanStart: timespanStart,
            timespanEnd: timespanEnd,
            height: controlledData.height,
            width: controlledData.width,
            maxLength: controlledData.useRecommendedLength
                ? 0
                : controlledData.maxLength,
            userName: getUsername() || "",
            userMail: getEmail() || "",
            language: i18n.language,
        };
        setAlertMessage({
            type: "info",
            message: t("timelapse.receive_email"),
        });
        await addTimelapseJob(data);
    };

    const handleCancel = async () => {
        const res = await cancelTimelapseGeneration({ uUID: jobStatus.uUID });
        if (res.data) {
            setAlertMessage({
                type: "info",
                message: "",
            });
            setIsCanceling(true);
            setTimeout(() => handleCancelled(), 10000); // TODO: hier auf job updates gucken
        }
    };

    const handleCancelled = useDebouncedCallback(() => {
        setAlertMessage(null);
        setErrorMessages(defaultErrorMessages);
        setIsValidation(false);
        setJobStatus(defaultJobStatus);
        setDelay({
            total: 0,
            current: 0,
        });
        setIsCanceling(false);
    }, 1000);

    const handleDownloadVideo = (url) => {
        try {
            setIsDownloadingVideo(true)
            setDownloadProgress(0)

            if (!url) return

            url = url.replace(/^\/+|\/+$/g, '') // remove leading and trailing slashes
            const xhr = new XMLHttpRequest()
            xhr.open("GET", url, true)
            xhr.responseType = "blob"

            xhr.onprogress = (event) => {
                const percentComplete = (event.loaded / event.total) * 100;
                setDownloadProgress(percentComplete);
            }

            xhr.onload = () => {
                if (xhr.status === 200) {
                    var camera = 
                        controlledData.camera.name ? 
                            cameras.length > 1 ? 
                                controlledData.camera.name : 
                                `${t("events.camera")} ${controlledData.camera.id + 1}` :
                            TITLE
                    var startString = controlledData.timespanStart.format("YY-MM-DD")
                    var endString = controlledData.timespanEnd.format("YY-MM-DD")
                    var fileName = camera + "_" + startString
                    var extension = ".mp4"

                    if (startString !== endString) {
                        fileName += "_" + endString
                    }

                    saveFile(xhr.response, fileName + extension)
                    setIsDownloadingVideo(false)
                }
            }

            xhr.onerror = (event) => {
                console.error(event)
            }

            xhr.send()
        } catch (error) {
            console.error(error)
            setIsDownloadingVideo(false)
            setAlertMessage({
                type: "error",
                message: t("timelapse.error.download_failed")
            })
        }
    }

    const handleChangeMaxLength = (event, value) => {
        setControlledData({
            ...controlledData,
            maxLength: value,
        });
    };

    const calculateProgress = () => {
        const progressJob = ((delay.total - delay.current) * 100) / delay.total || 0;
        const progress = !isDownloadingVideo
            ? progressJob / 2 || 0
            : (downloadProgress / 2 || 0) + 50;
        return progress;
    };
    
    const Progress = (props) => {
        return (
            <Box
                sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                borderBottomLeftRadius: "4px",
                borderBottomRightRadius: "4px",
                overflow: "hidden",
                }}
            >
                <LinearProgress variant="determinate" value={props.value} />
            </Box>
        );
    };

    useEffect(() => {
        if (error) {
            const errorStr = error?.message;
            setAlertMessage({
                type: "error",
                message: errorStr.substring(0, errorStr.indexOf(".") + 1),
            });
        }
    }, [error]);

    useEffect(() => {
        if (errorCancel) {
            const errorStr = errorCancel?.message;
            setAlertMessage({
                type: "error",
                message: errorStr.substring(0, errorStr.indexOf(".") + 1),
            });
        }
    }, [errorCancel]);

    useEffect(() => {
        // validate data
        let newErrorMessages = {
            camera: null,
            timespanStart: null,
            timespanEnd: null,
            maxLength: null,
        };

        let isValid = true;

        if (!controlledData.camera) {
            newErrorMessages.camera = t("timelapse.error.camera_required");
            isValid = false;
        }

        if (new Date(controlledData.timespanStart).getTime() > new Date(controlledData.timespanEnd).getTime()) {
            newErrorMessages.timespanStart = t("timelapse.error.timespan_invalid");
            isValid = false;
        }

        if (new Date(controlledData.timespanEnd).getTime() < new Date(controlledData.timespanStart).getTime()) {
            newErrorMessages.timespanEnd = t("timelapse.error.timespan_invalid");
            isValid = false;
        }

        if (controlledData.maxLength < 10 || controlledData.maxLength > 120) {
            newErrorMessages.maxLength = 
                t("timelapse.error.max_length_invalid", 
                {
                    min: 10,
                    max: 120,
                });
            isValid = false;
        }

        setErrorMessages(newErrorMessages);
        setIsValidation(!isValid);
    }, [controlledData]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        updateSiderWidth();
        window.addEventListener("resize", updateSiderWidth);
        return () => window.removeEventListener("resize", updateSiderWidth);
    }, [updateSiderWidth]);

    useEffect(() => {
        if (job?.uUID) {
            var newJobStatus = {
                ...jobStatus,
                ...job,
            }
            setJobStatus(newJobStatus);
        } else {
            setJobStatus(defaultJobStatus);
            setDelay({
                total: 0,
                current: 0,
            });
        }
    }, [job]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (dataJobStatus?.timelapseJobStatusUpdatedByUuid?.jobParameter.state === "finished") {
            setAlertMessage({
                type: "success",
                message: "",
            });
            setErrorMessages(defaultErrorMessages);
            setIsValidation(false);
            setDelay({
                total: 0,
                current: 0,
            });
            setJobStatus(defaultJobStatus);
            // download file
            handleDownloadVideo(dataJobStatus?.timelapseJobStatusUpdatedByUuid?.timelapseUrl);
        } else if (dataJobStatus?.timelapseJobStatusUpdatedByUuid?.jobParameter.state === "failed") {
            setErrorMessages(defaultErrorMessages);
            setIsValidation(false);
            setJobStatus(defaultJobStatus);
            setDelay({
                total: 0,
                current: 0,
            });
            setAlertMessage({
                type: "error",
                message: t("timelapse.error.job_failed"),
            });
        } else if (dataJobStatus?.timelapseJobStatusUpdatedByUuid?.jobParameter.state === "cancelled") {
            handleCancelled();
        } else {
            setJobStatus({
                ...jobStatus,
                ...dataJobStatus?.timelapseJobStatusUpdatedByUuid,
            });

            // Option 1 check equality of id
            // jobStatus?.uUID !== dataJobStatus?.timelapseJobStatusUpdatedByUuid?.uUID
            // Option 2 check if delay.total is 0
            if (!delay.total) {
                setDelay({
                    total: dataJobStatus?.timelapseJobStatusUpdatedByUuid?.jobParameter.delay || 0,
                    current: dataJobStatus?.timelapseJobStatusUpdatedByUuid?.jobParameter.delay || 0,
                });
            } else {
                setDelay({
                    total: delay.total,
                    current: dataJobStatus?.timelapseJobStatusUpdatedByUuid?.jobParameter.delay || 0,
                });
            }
        }
    }, [dataJobStatus]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        handleCancelled();
        setAlertMessage({
            type: "error",
            message: jobStatusError?.message,
        });
    }, [jobStatusError]); // eslint-disable-line react-hooks/exhaustive-deps

    // update original quality
    useEffect(() => {
        const quantity = cameras.find((camera) => camera.id === controlledData.camera.id)?.quality;

        if (quantity) {
            setQuality(quantity);
            setControlledData({
                ...controlledData,
                width: quantity.original.width,
                height: quantity.original.height,
            });
        } else if (controlledData.camera?.image) {
            const img = new Image();
            img.src = controlledData.camera.image;
            img.decode()
            .then(() => {
                setQuality({
                    original: {
                        width: img.width,
                        height: img.height,
                    },
                    reduced: {
                        width: Math.round(img.width / 1.5),
                        height: Math.round(img.height / 1.5),
                    },
                });

                // set window.conf
                const oldCameras = window.conf.CAMERAS;
                const newCameras = oldCameras.map((camera) => {
                    if (camera.id === controlledData.camera.id) {
                        return {
                            ...camera,
                            quality: {
                                original: {
                                    width: img.width,
                                    height: img.height,
                                },
                                reduced: {
                                    width: Math.round(img.width / 1.5),
                                    height: Math.round(img.height / 1.5),
                                },
                            },
                        };
                    }
                    return camera;
                });

                window.conf.CAMERAS = newCameras;

                setControlledData({
                    ...controlledData,
                    width: img.width,
                    height: img.height,
                });
            })
            .catch(error => console.error(error));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controlledData.camera]);

    return (
        <>
            <Box
                sx={{ width: "100%" }}
                id="timelapse-form"
                ref={refSider}
            >
                {cameras.length > 1 && (
                    <TextField
                        select
                        label={t("events.camera")}
                        value={controlledData.camera.id}
                        onChange={onChangeSelectedCamera}
                        fullWidth
                        sx={{ pb: 2 }}
                        disabled={!cameras?.length || disabledForm}
                    >
                        {cameras.map((camera) => (
                            <MenuItem key={camera.id} value={camera.id}>
                                {camera.name !== "" ? camera.name : `${t("events.camera")} ${camera.id + 1}`}
                            </MenuItem>
                        ))}
                    </TextField>
                )}

                <DateRangePicker
                    isDisabled={controlledData.camera == null || disabledForm}
                    isLoading={isCameraLoading}
                    value={[controlledData.timespanStart, controlledData.timespanEnd]}
                    onChange={(dateRange) => {
                        setControlledData({
                            ...controlledData,
                            timespanStart: dateRange[0],
                            timespanEnd: dateRange[1],
                        });
                        setAlertMessage(null);
                    }}
                    error={[errorMessages.timespanStart, errorMessages.timespanEnd]}
                    onError={(errors) => {
                        setErrorMessages({
                            ...errorMessages,
                            timespanStart: errors[0],
                            timespanEnd: errors[1],
                        });
                    }}
                    siderWidth={siderWidth}
                />

                <Box sx={{
                    p: 2,
                    marginTop: 0,
                    borderRadius: "4px",
                    border: "1px solid rgba(0, 0, 0, 0.12)",
                    userSelect: "none"
                }}>

                    <Box sx={{ width: "100%" }}>
                        <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={controlledData.useRecommendedLength}
                                    onChange={(event) => {
                                        setControlledData({
                                            ...controlledData,
                                            useRecommendedLength: event.target.checked,
                                        });
                                        setAlertMessage(null);
                                    }}
                                    disabled={disabledForm}
                                />
                            }
                            label={t("timelapse.use_recommended_length")}
                        />
                        </FormGroup>
                    </Box>

                    <Box sx={{ 
                        width: "100%",
                        marginTop: 1,
                        position: "relative"
                    }}>

                        <Typography sx={{ color: disabledForm || controlledData.useRecommendedLength ? "#bdbdbd" : "rgba(0, 0, 0, 0.87)" }}>
                            {t("timelapse.maximum_length")}
                            {!controlledData.useRecommendedLength && `: ${controlledData.maxLength} ${t("timelapse.seconds")}`}
                        </Typography>

                        {errorMessages.maxLength && (
                            <Typography variant="caption" color="red">
                                {errorMessages.maxLength}
                            </Typography>
                        )}

                        <Box style={{
                            boxSizing: "content-box",
                            padding: "0 10px 10px",
                        }}>

                            <PrimarySliderCustom
                                aria-label={t("timelapse.maximum_length")}
                                value={controlledData.maxLength}
                                getAriaValueText={(value) => `${value}%`}
                                step={10}
                                min={10}
                                max={120}
                                disabled={disabledForm || controlledData.useRecommendedLength}
                                valueLabelDisplay="auto"
                                marks={[
                                    {
                                        value: 10,
                                        label: "10",
                                    },
                                    {
                                        value: 120,
                                        label: "120",
                                    }
                                ]}
                                onChange={handleChangeMaxLength}
                            />

                            <Box sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                position: "absolute",
                                left: 0,
                                bottom: 10
                            }}>

                                <Typography 
                                  sx={{ 
                                    fontSize: "0.875rem",
                                    color:
                                        disabledForm || controlledData.useRecommendedLength || controlledData.maxLength !== 10
                                            ? "#bdbdbd"
                                            : "rgba(0, 0, 0, 0.87)",
                                  }}
                                >
                                    10
                                </Typography>

                                <Typography 
                                  sx={{ 
                                    fontSize: "0.875rem",
                                    color:
                                        disabledForm || controlledData.useRecommendedLength || controlledData.maxLength !== 120
                                            ? "#bdbdbd"
                                            : "rgba(0, 0, 0, 0.87)",
                                  }}
                                >
                                    120
                                </Typography>

                            </Box>
                        </Box>
                    </Box>

                    <Divider />

                    <Box sx={{
                        width: "100%",
                        marginTop: 2,
                        position: "relative",
                    }}>
                        <Typography sx={{ color: disabledForm ? "#bdbdbd" : "rgba(0, 0, 0, 0.87)"}}>
                            {t("timelapse.quality")}: {controlledData.width}x
                            {controlledData.height}
                        </Typography>
                        <Box style={{
                            boxSizing: "content-box",
                            padding: "0 10px",
                        }}>
                            <PrimarySliderCustom
                                aria-label={t("timelapse.quality")}
                                value={controlledData.width === quality.reduced.width ? 1 : 2}
                                getAriaValueText={() => `${controlledData.width}x${controlledData.height}`}
                                valueLabelFormat={() => `${controlledData.width}x${controlledData.height}`}
                                disabled={disabledForm}
                                step={1}
                                min={1}
                                max={2}
                                valueLabelDisplay="auto"
                                marks={[
                                {
                                    value: 1,
                                    label: `${quality.reduced.width}x${quality.reduced.height}`,
                                },
                                {
                                    value: 2,
                                    label: `${quality.original.width}x${quality.original.height}`,
                                },
                                ]}
                                onChange={(event, value) => {
                                    setControlledData({
                                        ...controlledData,
                                        width:
                                        value === 1
                                            ? quality.reduced.width
                                            : quality.original.width,
                                        height:
                                        value === 1
                                            ? quality.reduced.height
                                            : quality.original.height,
                                    });
                                    setAlertMessage(null);
                                }}
                            />
                            <Box sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                position: "absolute",
                                left: 0,
                                bottom: 0,
                            }}>
                                <Typography sx={{ 
                                    fontSize: "0.875rem",
                                    color: disabledForm || controlledData.width !== quality.reduced.width
                                        ? "#bdbdbd"
                                        : "rgba(0, 0, 0, 0.87)"
                                }}>
                                    {t("timelapse.reduced")}
                                </Typography>
                                <Typography sx={{ 
                                    fontSize: "0.875rem",
                                    color: disabledForm || controlledData.width !== quality.original.width
                                        ? "#bdbdbd"
                                        : "rgba(0, 0, 0, 0.87)"
                                }}>
                                    {t("timelapse.original")}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                {alertMessage?.message && (
                <Box sx={{
                    width: "100%",
                    mt: 2,
                }}>
                    <Alert
                        variant="filled"
                        severity={alertMessage?.type}
                        sx={{ alignItems: "center", userSelect: "none" }}
                    >
                        {alertMessage?.message}
                    </Alert>
                </Box>)}

            </Box>

            <Box sx={{ mt: "auto" }} />

            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "8px",
                }}
            >
                <Box
                    sx={{
                        position: "relative",
                        width: "100%",
                    }}
                >
                    <LoadingButton
                        sx={{ width: "100%", mt: 2, transition: "all 0.3s ease-in-out" }}
                        variant="contained"
                        size="large"
                        startIcon={<Download />}
                        onClick={handleAddTimelapseJob}
                        disabled={disabledButton}
                        loading={loadingJob || jobProgress || isDownloadingVideo}
                        loadingIndicator={
                            delay.total === 0 && !isDownloadingVideo ? (
                                <CircularProgress size={24} />
                            ) : (
                                <p style={{
                                    width: "180px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                }}>
                                    {isDownloadingVideo ? t("actions.downloading") : t("timelapse.loading")}...
                                </p>
                            )
                        }
                    >
                        {t("actions.download")}
                    </LoadingButton>

                    {(((loadingJob || jobProgress) && delay.total > 0) || isDownloadingVideo) &&
                    <Progress value={calculateProgress()} />}

                </Box>

                {jobProgress && !isCanceling && (
                <Button
                    sx={{ flexShrink: 0, mt: 2 }}
                    variant="outlined"
                    size="large"
                    disabled={loadingCancel || isCanceling}
                    onClick={handleCancel}
                >
                    <Close />
                </Button>)}
            </Box>
        </>
    )
}